@font-face {
  font-family: "icoscorimmo";
  src: url("../fonts/icoscorimmo.eot");
  src: url("../fonts/icoscorimmo.eot") format("embedded-opentype"), url("../fonts/icoscorimmo.woff") format("woff"), url("../fonts/icoscorimmo.ttf") format("truetype"), url("../fonts/icoscorimmo.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=scorimmo-icon-],
[class*=" scorimmo-icon-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=scorimmo-icon-]:before,
[class*=" scorimmo-icon-"]:before {
  font-family: "icoscorimmo";
  text-align: center;
  background-repeat: no-repeat;
}

.scorimmo-icon-Arrow-Curved-Down:before {
  content: "\e900";
}

.scorimmo-icon-Arrow-Curved-Left:before {
  content: "\e901";
}

.scorimmo-icon-Arrow-Curved-Right:before {
  content: "\e902";
}

.scorimmo-icon-Arrow-Curved-Up:before {
  content: "\e903";
}

.scorimmo-icon-Arrow-Down-1:before {
  content: "\e904";
}

.scorimmo-icon-Arrow-Down-2:before {
  content: "\e905";
}

.scorimmo-icon-Arrow-Down-3:before {
  content: "\e906";
}

.scorimmo-icon-Arrow-Down-4:before {
  content: "\e907";
}

.scorimmo-icon-Arrow-Down-5:before {
  content: "\e908";
}

.scorimmo-icon-Arrow-Down-6:before {
  content: "\e909";
}

.scorimmo-icon-Arrow-Exit-1:before {
  content: "\e90a";
}

.scorimmo-icon-Arrow-Exit-2:before {
  content: "\e90b";
}

.scorimmo-icon-Arrow-Infinite:before {
  content: "\e90c";
}

.scorimmo-icon-Arrow-Left-1:before {
  content: "\e90d";
}

.scorimmo-icon-Arrow-Left-2:before {
  content: "\e90e";
}

.scorimmo-icon-Arrow-Left-3:before {
  content: "\e90f";
}

.scorimmo-icon-Arrow-Left-4:before {
  content: "\e910";
}

.scorimmo-icon-Arrow-Left-5:before {
  content: "\e911";
}

.scorimmo-icon-Arrow-Left-6:before {
  content: "\e912";
}

.scorimmo-icon-Arrow-Move:before {
  content: "\e913";
}

.scorimmo-icon-Arrow-Popout:before {
  content: "\e914";
}

.scorimmo-icon-Arrow-Right-1:before {
  content: "\e915";
}

.scorimmo-icon-Arrow-Right-2:before {
  content: "\e916";
}

.scorimmo-icon-Arrow-Right-3:before {
  content: "\e917";
}

.scorimmo-icon-Arrow-Right-4:before {
  content: "\e918";
}

.scorimmo-icon-Arrow-Right-5:before {
  content: "\e919";
}

.scorimmo-icon-Arrow-Right-6:before {
  content: "\e91a";
}

.scorimmo-icon-Arrow-Right-Angle-1:before {
  content: "\e91b";
}

.scorimmo-icon-Arrow-Right-Angle-2:before {
  content: "\e91c";
}

.scorimmo-icon-Arrow-Shuffle:before {
  content: "\e91d";
}

.scorimmo-icon-Arrow-Up-1:before {
  content: "\e91e";
}

.scorimmo-icon-Arrow-Up-2:before {
  content: "\e91f";
}

.scorimmo-icon-Arrow-Up-3:before {
  content: "\e920";
}

.scorimmo-icon-Arrow-Up-4:before {
  content: "\e921";
}

.scorimmo-icon-Arrow-Up-5:before {
  content: "\e922";
}

.scorimmo-icon-Arrow-Up-6:before {
  content: "\e923";
}

.scorimmo-icon-ArrowFull-Screen-Reverse:before {
  content: "\e924";
}

.scorimmo-icon-ArrowFull-Screen:before {
  content: "\e925";
}

.scorimmo-icon-Location-Atlas:before {
  content: "\e926";
}

.scorimmo-icon-Location-Billboard-1:before {
  content: "\e927";
}

.scorimmo-icon-Location-Billboard-2:before {
  content: "\e928";
}

.scorimmo-icon-Location-Compass:before {
  content: "\e929";
}

.scorimmo-icon-Location-Flag-1:before {
  content: "\e92a";
}

.scorimmo-icon-Location-Flag-2:before {
  content: "\e92b";
}

.scorimmo-icon-Location-Globe-1:before {
  content: "\e92c";
}

.scorimmo-icon-Location-Globe-2:before {
  content: "\e92d";
}

.scorimmo-icon-Location-Globe-with-Arrows:before {
  content: "\e92e";
}

.scorimmo-icon-Location-Globes:before {
  content: "\e92f";
}

.scorimmo-icon-Location-Map-1:before {
  content: "\e930";
}

.scorimmo-icon-Location-Map-2:before {
  content: "\e931";
}

.scorimmo-icon-Location-Pin-1:before {
  content: "\e932";
}

.scorimmo-icon-Location-Pin-2:before {
  content: "\e933";
}

.scorimmo-icon-Location-Pin-3:before {
  content: "\e934";
}

.scorimmo-icon-Location-Radar:before {
  content: "\e935";
}

.scorimmo-icon-Location-Road-Signs:before {
  content: "\e936";
}

.scorimmo-icon-Location-Sign-Post:before {
  content: "\e937";
}

.scorimmo-icon-Location-Target:before {
  content: "\e938";
}

.scorimmo-icon-Location-Turn-Ahead:before {
  content: "\e939";
}

.scorimmo-icon-Misc-Admission-Ticket:before {
  content: "\e93a";
}

.scorimmo-icon-Misc-Admission-Tickets:before {
  content: "\e93b";
}

.scorimmo-icon-Misc-Atom:before {
  content: "\e93c";
}

.scorimmo-icon-Misc-Bandaid:before {
  content: "\e93d";
}

.scorimmo-icon-Misc-Beaker-1:before {
  content: "\e93e";
}

.scorimmo-icon-Misc-Beaker-2:before {
  content: "\e93f";
}

.scorimmo-icon-Misc-Beaker-3:before {
  content: "\e940";
}

.scorimmo-icon-Misc-Binoculars:before {
  content: "\e941";
}

.scorimmo-icon-Misc-Bone:before {
  content: "\e942";
}

.scorimmo-icon-Misc-Bookmark-1:before {
  content: "\e943";
}

.scorimmo-icon-Misc-Bookmark-2:before {
  content: "\e944";
}

.scorimmo-icon-Misc-Bookshelf:before {
  content: "\e945";
}

.scorimmo-icon-Misc-Bottle:before {
  content: "\e946";
}

.scorimmo-icon-Misc-Bow-Tie:before {
  content: "\e947";
}

.scorimmo-icon-Misc-Broken-Heart-1:before {
  content: "\e948";
}

.scorimmo-icon-Misc-Broken-Heart-2:before {
  content: "\e949";
}

.scorimmo-icon-Misc-Circle-Outline-Down:before {
  content: "\e94a";
}

.scorimmo-icon-Misc-Circle-Outline-Up:before {
  content: "\e94b";
}

.scorimmo-icon-Misc-Circle-Outline-with-Dot:before {
  content: "\e94c";
}

.scorimmo-icon-Misc-Circle-Outline:before {
  content: "\e94d";
}

.scorimmo-icon-Misc-Crossbones:before {
  content: "\e94e";
}

.scorimmo-icon-Misc-Desk:before {
  content: "\e94f";
}

.scorimmo-icon-Misc-Drawers-1:before {
  content: "\e950";
}

.scorimmo-icon-Misc-Drawers-2:before {
  content: "\e951";
}

.scorimmo-icon-Misc-Drawers-3:before {
  content: "\e952";
}

.scorimmo-icon-Misc-Graduation:before {
  content: "\e953";
}

.scorimmo-icon-Misc-Grid-1:before {
  content: "\e954";
}

.scorimmo-icon-Misc-Grid-2:before {
  content: "\e955";
}

.scorimmo-icon-Misc-Grid-3:before {
  content: "\e956";
}

.scorimmo-icon-Misc-Grid-4:before {
  content: "\e957";
}

.scorimmo-icon-Misc-Grid-5:before {
  content: "\e958";
}

.scorimmo-icon-Misc-Grid-6:before {
  content: "\e959";
}

.scorimmo-icon-Misc-Grid-7:before {
  content: "\e95a";
}

.scorimmo-icon-Misc-Grid-8:before {
  content: "\e95b";
}

.scorimmo-icon-Misc-Grid-9:before {
  content: "\e95c";
}

.scorimmo-icon-Misc-Grid-10:before {
  content: "\e95d";
}

.scorimmo-icon-Misc-Grid-11:before {
  content: "\e95e";
}

.scorimmo-icon-Misc-Grid-12:before {
  content: "\e95f";
}

.scorimmo-icon-Misc-Health-Circle:before {
  content: "\e960";
}

.scorimmo-icon-Misc-Health-Square:before {
  content: "\e961";
}

.scorimmo-icon-Misc-Medicine-Bottle:before {
  content: "\e962";
}

.scorimmo-icon-Misc-Music-Note-in-a-Circle:before {
  content: "\e963";
}

.scorimmo-icon-Misc-Music-Note-in-a-Square-1:before {
  content: "\e964";
}

.scorimmo-icon-Misc-Music-Note-in-a-Square-2:before {
  content: "\e965";
}

.scorimmo-icon-Misc-Music-Note-in-a-Square-3:before {
  content: "\e966";
}

.scorimmo-icon-Misc-Neck-Tie:before {
  content: "\e967";
}

.scorimmo-icon-Misc-Paper-Clip:before {
  content: "\e968";
}

.scorimmo-icon-Misc-Pill:before {
  content: "\e969";
}

.scorimmo-icon-Misc-Player-Eject:before {
  content: "\e96a";
}

.scorimmo-icon-Misc-Player-Fast-Forward:before {
  content: "\e96b";
}

.scorimmo-icon-Misc-Player-Next:before {
  content: "\e96c";
}

.scorimmo-icon-Misc-Player-Pause:before {
  content: "\e96d";
}

.scorimmo-icon-Misc-Player-Play:before {
  content: "\e96e";
}

.scorimmo-icon-Misc-Player-Record:before {
  content: "\e96f";
}

.scorimmo-icon-Misc-Player-Stop:before {
  content: "\e970";
}

.scorimmo-icon-Misc-Posicle:before {
  content: "\e971";
}

.scorimmo-icon-Misc-Presentation-1:before {
  content: "\e972";
}

.scorimmo-icon-Misc-Presentation-2:before {
  content: "\e973";
}

.scorimmo-icon-Misc-Presentation-3:before {
  content: "\e974";
}

.scorimmo-icon-Misc-Presentation-4:before {
  content: "\e975";
}

.scorimmo-icon-Misc-Presentation-5:before {
  content: "\e976";
}

.scorimmo-icon-Misc-Rocket:before {
  content: "\e977";
}

.scorimmo-icon-Misc-Safe:before {
  content: "\e978";
}

.scorimmo-icon-Misc-Scissors:before {
  content: "\e979";
}

.scorimmo-icon-Misc-Square-Outline-Down:before {
  content: "\e97a";
}

.scorimmo-icon-Misc-Square-Outline-Up:before {
  content: "\e97b";
}

.scorimmo-icon-Misc-Square-Outline-with-Cube:before {
  content: "\e97c";
}

.scorimmo-icon-Misc-Square-Outline:before {
  content: "\e97d";
}

.scorimmo-icon-Misc-Stack-1:before {
  content: "\e97e";
}

.scorimmo-icon-Misc-Stack-2:before {
  content: "\e97f";
}

.scorimmo-icon-Misc-Target:before {
  content: "\e980";
}

.scorimmo-icon-Misc-Tea-Cup:before {
  content: "\e981";
}

.scorimmo-icon-Misc-Test-Tube:before {
  content: "\e982";
}

.scorimmo-icon-Misc-Text-Align-Center:before {
  content: "\e983";
}

.scorimmo-icon-Misc-Text-Align-Justify:before {
  content: "\e984";
}

.scorimmo-icon-Misc-Text-Align-Left:before {
  content: "\e985";
}

.scorimmo-icon-Misc-Text-Align-Right:before {
  content: "\e986";
}

.scorimmo-icon-Misc-Top-Hat-with-Cane:before {
  content: "\e987";
}

.scorimmo-icon-Misc-Tree:before {
  content: "\e988";
}

.scorimmo-icon-Misc-Wine-Glass:before {
  content: "\e989";
}

.scorimmo-icon-Newspaper:before {
  content: "\e98a";
}

.scorimmo-icon-Other-Add:before {
  content: "\e98b";
}

.scorimmo-icon-Other-Address-Book:before {
  content: "\e98c";
}

.scorimmo-icon-Other-Bar-Chart-1:before {
  content: "\e98d";
}

.scorimmo-icon-Other-Bar-Chart-2:before {
  content: "\e98e";
}

.scorimmo-icon-Other-Bell:before {
  content: "\e98f";
}

.scorimmo-icon-Other-Book:before {
  content: "\e990";
}

.scorimmo-icon-Other-Box-Download:before {
  content: "\e991";
}

.scorimmo-icon-Other-Box-Upload:before {
  content: "\e992";
}

.scorimmo-icon-Other-Box-with-More:before {
  content: "\e993";
}

.scorimmo-icon-Other-Box-with-Papers:before {
  content: "\e994";
}

.scorimmo-icon-Other-Box-with-Video-Files:before {
  content: "\e995";
}

.scorimmo-icon-Other-Box:before {
  content: "\e996";
}

.scorimmo-icon-Other-Briefcase:before {
  content: "\e997";
}

.scorimmo-icon-Other-Building:before {
  content: "\e998";
}

.scorimmo-icon-Other-Calendar-1:before {
  content: "\e999";
}

.scorimmo-icon-Other-Calendar-2:before {
  content: "\e99a";
}

.scorimmo-icon-Other-Chat-Bubble-Dots:before {
  content: "\e99b";
}

.scorimmo-icon-Other-Chat-Bubble:before {
  content: "\e99c";
}

.scorimmo-icon-Other-Chat-Bubbles:before {
  content: "\e99d";
}

.scorimmo-icon-Other-Checkmark-1:before {
  content: "\e99e";
}

.scorimmo-icon-Other-Checkmark-2:before {
  content: "\e99f";
}

.scorimmo-icon-Other-Clipboard:before {
  content: "\e9a0";
}

.scorimmo-icon-Other-Clock-1:before {
  content: "\e9a1";
}

.scorimmo-icon-Other-Clock-2:before {
  content: "\e9a2";
}

.scorimmo-icon-Other-Contact-Card-1:before {
  content: "\e9a3";
}

.scorimmo-icon-Other-Contact-Card-2:before {
  content: "\e9a4";
}

.scorimmo-icon-Other-Contact-Cards-1:before {
  content: "\e9a5";
}

.scorimmo-icon-Other-Contact-Cards-2:before {
  content: "\e9a6";
}

.scorimmo-icon-Other-Diamond:before {
  content: "\e9a7";
}

.scorimmo-icon-Other-Envelope:before {
  content: "\e9a8";
}

.scorimmo-icon-Other-Envelopes:before {
  content: "\e9a9";
}

.scorimmo-icon-Other-Error:before {
  content: "\e9aa";
}

.scorimmo-icon-Other-Eye:before {
  content: "\e9ab";
}

.scorimmo-icon-Other-Filter-Add:before {
  content: "\e9ac";
}

.scorimmo-icon-Other-Filter-Remove:before {
  content: "\e9ad";
}

.scorimmo-icon-Other-Filter:before {
  content: "\e9ae";
}

.scorimmo-icon-Other-Folder-More:before {
  content: "\e9af";
}

.scorimmo-icon-Other-Folder-Search:before {
  content: "\e9b0";
}

.scorimmo-icon-Other-Folder:before {
  content: "\e9b1";
}

.scorimmo-icon-Other-Folders:before {
  content: "\e9b2";
}

.scorimmo-icon-Other-Forked-Path:before {
  content: "\e9b3";
}

.scorimmo-icon-Other-Gift:before {
  content: "\e9b4";
}

.scorimmo-icon-Other-Handshake:before {
  content: "\e9b5";
}

.scorimmo-icon-Other-Heart:before {
  content: "\e9b6";
}

.scorimmo-icon-Other-Help:before {
  content: "\e9b7";
}

.scorimmo-icon-Other-Home:before {
  content: "\e9b8";
}

.scorimmo-icon-Other-Info:before {
  content: "\e9b9";
}

.scorimmo-icon-Other-Key-1:before {
  content: "\e9ba";
}

.scorimmo-icon-Other-Key-2:before {
  content: "\e9bb";
}

.scorimmo-icon-Other-Life-Preserver:before {
  content: "\e9bc";
}

.scorimmo-icon-Other-Lightning-Bolt:before {
  content: "\e9bd";
}

.scorimmo-icon-Other-List-1:before {
  content: "\e9be";
}

.scorimmo-icon-Other-List-2:before {
  content: "\e9bf";
}

.scorimmo-icon-Other-List-3:before {
  content: "\e9c0";
}

.scorimmo-icon-Other-Lock:before {
  content: "\e9c1";
}

.scorimmo-icon-Other-Magnify-Glass:before {
  content: "\e9c2";
}

.scorimmo-icon-Other-More-1:before {
  content: "\e9c3";
}

.scorimmo-icon-Other-More-2:before {
  content: "\e9c4";
}

.scorimmo-icon-Other-Music-Note-1:before {
  content: "\e9c5";
}

.scorimmo-icon-Other-Music-Note-2:before {
  content: "\e9c6";
}

.scorimmo-icon-Other-Newspapers:before {
  content: "\e9c7";
}

.scorimmo-icon-Other-No-Entry:before {
  content: "\e9c8";
}

.scorimmo-icon-Other-Note:before {
  content: "\e9c9";
}

.scorimmo-icon-Other-Notepad:before {
  content: "\e9ca";
}

.scorimmo-icon-Other-Old-Building:before {
  content: "\e9cb";
}

.scorimmo-icon-Other-Page-1:before {
  content: "\e9cc";
}

.scorimmo-icon-Other-Page-2:before {
  content: "\e9cd";
}

.scorimmo-icon-Other-Pages-1:before {
  content: "\e9ce";
}

.scorimmo-icon-Other-Pages-2:before {
  content: "\e9cf";
}

.scorimmo-icon-Other-Paper-Airplane:before {
  content: "\e9d0";
}

.scorimmo-icon-Other-Pencil:before {
  content: "\e9d1";
}

.scorimmo-icon-Other-Photo-List:before {
  content: "\e9d2";
}

.scorimmo-icon-Other-Pie-Chart:before {
  content: "\e9d3";
}

.scorimmo-icon-Other-Remove:before {
  content: "\e9d4";
}

.scorimmo-icon-Other-Settings-1:before {
  content: "\e9d5";
}

.scorimmo-icon-Other-Settings-2:before {
  content: "\e9d6";
}

.scorimmo-icon-Other-Settings-3:before {
  content: "\e9d7";
}

.scorimmo-icon-Other-Sheild:before {
  content: "\e9d8";
}

.scorimmo-icon-Other-Star-1:before {
  content: "\e9d9";
}

.scorimmo-icon-Other-Star-2:before {
  content: "\e9da";
}

.scorimmo-icon-Other-Star-in-Circle:before {
  content: "\e9db";
}

.scorimmo-icon-Other-Stars:before {
  content: "\e9dc";
}

.scorimmo-icon-Other-Storefront:before {
  content: "\e9dd";
}

.scorimmo-icon-Other-Suitcase:before {
  content: "\e9de";
}

.scorimmo-icon-Other-Suitcases:before {
  content: "\e9df";
}

.scorimmo-icon-Other-Tag:before {
  content: "\e9e0";
}

.scorimmo-icon-Other-Tags:before {
  content: "\e9e1";
}

.scorimmo-icon-Other-Thumb-Down:before {
  content: "\e9e2";
}

.scorimmo-icon-Other-Thumb-Up:before {
  content: "\e9e3";
}

.scorimmo-icon-Other-Toggles-1:before {
  content: "\e9e4";
}

.scorimmo-icon-Other-Toggles-2:before {
  content: "\e9e5";
}

.scorimmo-icon-Other-Toggles-3:before {
  content: "\e9e6";
}

.scorimmo-icon-Other-Top-Charts:before {
  content: "\e9e7";
}

.scorimmo-icon-Other-Trash-Full:before {
  content: "\e9e8";
}

.scorimmo-icon-Other-Trash:before {
  content: "\e9e9";
}

.scorimmo-icon-Other-Unlock:before {
  content: "\e9ea";
}

.scorimmo-icon-Other-User-Female-1:before {
  content: "\e9eb";
}

.scorimmo-icon-Other-User-Female-2:before {
  content: "\e9ec";
}

.scorimmo-icon-Other-User-Male-1:before {
  content: "\e9ed";
}

.scorimmo-icon-Other-User-Male-2:before {
  content: "\e9ee";
}

.scorimmo-icon-Other-Users-1:before {
  content: "\e9ef";
}

.scorimmo-icon-Other-Users-2:before {
  content: "\e9f0";
}

.scorimmo-icon-Other-Video-1:before {
  content: "\e9f1";
}

.scorimmo-icon-Other-Video-2:before {
  content: "\e9f2";
}

.scorimmo-icon-Other-Videos:before {
  content: "\e9f3";
}

.scorimmo-icon-Other-Warning:before {
  content: "\e9f4";
}

.scorimmo-icon-Other-Wrench:before {
  content: "\e9f5";
}

.scorimmo-icon-Other-Write:before {
  content: "\e9f6";
}

.scorimmo-icon-Other-X:before {
  content: "\e9f7";
}

.scorimmo-icon-Other-Zoom-In:before {
  content: "\e9f8";
}

.scorimmo-icon-Other-Zoom-Out:before {
  content: "\e9f9";
}

.scorimmo-icon-Social-Dribbble-Circle:before {
  content: "\e9fa";
}

.scorimmo-icon-Social-Dribbble-Square:before {
  content: "\e9fb";
}

.scorimmo-icon-Social-Dropbox-Circle:before {
  content: "\e9fc";
}

.scorimmo-icon-Social-Dropbox-Square:before {
  content: "\e9fd";
}

.scorimmo-icon-Social-Facebook-Circle:before {
  content: "\e9fe";
}

.scorimmo-icon-Social-Facebook-Square:before {
  content: "\e9ff";
}

.scorimmo-icon-Social-Google-Plus-Circle:before {
  content: "\ea00";
}

.scorimmo-icon-Social-Google-Plus-Square:before {
  content: "\ea01";
}

.scorimmo-icon-Social-Instagram-Circle:before {
  content: "\ea02";
}

.scorimmo-icon-Social-Instagram-Square:before {
  content: "\ea03";
}

.scorimmo-icon-Social-LinkedIn-Circle:before {
  content: "\ea04";
}

.scorimmo-icon-Social-LinkedIn-Square:before {
  content: "\ea05";
}

.scorimmo-icon-Social-Pinterest-Circle:before {
  content: "\ea06";
}

.scorimmo-icon-Social-Pinterest-Square:before {
  content: "\ea07";
}

.scorimmo-icon-Social-Tumblr-Circle:before {
  content: "\ea08";
}

.scorimmo-icon-Social-Tumblr-Square:before {
  content: "\ea09";
}

.scorimmo-icon-Social-Twitter-Circle:before {
  content: "\ea0a";
}

.scorimmo-icon-Social-Twitter-Square:before {
  content: "\ea0b";
}

.scorimmo-icon-Social-Vimeo-Circle:before {
  content: "\ea0c";
}

.scorimmo-icon-Social-Vimeo-Square:before {
  content: "\ea0d";
}

.scorimmo-icon-Social-WordPress-Circle:before {
  content: "\ea0e";
}

.scorimmo-icon-Social-WordPress-Square:before {
  content: "\ea0f";
}

.scorimmo-icon-Social-Yahoo-Circle:before {
  content: "\ea10";
}

.scorimmo-icon-Social-Yahoo-Square:before {
  content: "\ea11";
}

.scorimmo-icon-Social-YouTube-Circle:before {
  content: "\ea12";
}

.scorimmo-icon-Social-YouTube-Square:before {
  content: "\ea13";
}

.scorimmo-icon-Store-Bitcoin-Coin:before {
  content: "\ea14";
}

.scorimmo-icon-Store-Bitcoin-Coins:before {
  content: "\ea15";
}

.scorimmo-icon-Store-Cash-Register:before {
  content: "\ea16";
}

.scorimmo-icon-Store-Credit-Card:before {
  content: "\ea17";
}

.scorimmo-icon-Store-Credit-Cards:before {
  content: "\ea18";
}

.scorimmo-icon-Store-Dollar-Coin:before {
  content: "\ea19";
}

.scorimmo-icon-Store-Dollar-Coins:before {
  content: "\ea1a";
}

.scorimmo-icon-Store-Euro-Coin:before {
  content: "\ea1b";
}

.scorimmo-icon-Store-Euro-Coins:before {
  content: "\ea1c";
}

.scorimmo-icon-Store-Handbag:before {
  content: "\ea1d";
}

.scorimmo-icon-Store-Paper-Bitcoin-1:before {
  content: "\ea1e";
}

.scorimmo-icon-Store-Paper-Bitcoin-2:before {
  content: "\ea1f";
}

.scorimmo-icon-Store-Paper-Bitcoin:before {
  content: "\ea20";
}

.scorimmo-icon-Store-Paper-Dollar:before {
  content: "\ea21";
}

.scorimmo-icon-Store-Paper-Dollars-1:before {
  content: "\ea22";
}

.scorimmo-icon-Store-Paper-Dollars-2:before {
  content: "\ea23";
}

.scorimmo-icon-Store-Paper-Euro:before {
  content: "\ea24";
}

.scorimmo-icon-Store-Paper-Euros-1:before {
  content: "\ea25";
}

.scorimmo-icon-Store-Paper-Euros-2:before {
  content: "\ea26";
}

.scorimmo-icon-Store-Receipt:before {
  content: "\ea27";
}

.scorimmo-icon-Store-Receipts:before {
  content: "\ea28";
}

.scorimmo-icon-Store-Shopping-Bag:before {
  content: "\ea29";
}

.scorimmo-icon-Store-Shopping-Bags:before {
  content: "\ea2a";
}

.scorimmo-icon-Store-Shopping-Basket-In:before {
  content: "\ea2b";
}

.scorimmo-icon-Store-Shopping-Basket-Left:before {
  content: "\ea2c";
}

.scorimmo-icon-Store-Shopping-Basket-Minus:before {
  content: "\ea2d";
}

.scorimmo-icon-Store-Shopping-Basket-Out:before {
  content: "\ea2e";
}

.scorimmo-icon-Store-Shopping-Basket-Plus:before {
  content: "\ea2f";
}

.scorimmo-icon-Store-Shopping-Basket-Right:before {
  content: "\ea30";
}

.scorimmo-icon-Store-Shopping-Basket:before {
  content: "\ea31";
}

.scorimmo-icon-Store-Shopping-Cart-In:before {
  content: "\ea32";
}

.scorimmo-icon-Store-Shopping-Cart-Left:before {
  content: "\ea33";
}

.scorimmo-icon-Store-Shopping-Cart-Minus:before {
  content: "\ea34";
}

.scorimmo-icon-Store-Shopping-Cart-Out:before {
  content: "\ea35";
}

.scorimmo-icon-Store-Shopping-Cart-Plus:before {
  content: "\ea36";
}

.scorimmo-icon-Store-Shopping-Cart-Right:before {
  content: "\ea37";
}

.scorimmo-icon-Store-Shopping-Cart:before {
  content: "\ea38";
}

.scorimmo-icon-Store-Wallet-1:before {
  content: "\ea39";
}

.scorimmo-icon-Store-Wallet-2:before {
  content: "\ea3a";
}

.scorimmo-icon-Store-Wallet-3:before {
  content: "\ea3b";
}

.scorimmo-icon-Weather-Celsius:before {
  content: "\ea3c";
}

.scorimmo-icon-Weather-Cloud-1:before {
  content: "\ea3d";
}

.scorimmo-icon-Weather-Cloud-2:before {
  content: "\ea3e";
}

.scorimmo-icon-Weather-Cloud-with-Lightning:before {
  content: "\ea3f";
}

.scorimmo-icon-Weather-Cloud-with-Rain-1:before {
  content: "\ea40";
}

.scorimmo-icon-Weather-Cloud-with-Rain-2:before {
  content: "\ea41";
}

.scorimmo-icon-Weather-Cloud-with-Rain-3:before {
  content: "\ea42";
}

.scorimmo-icon-Weather-Cloud-with-Rain-Lightning:before {
  content: "\ea43";
}

.scorimmo-icon-Weather-Cloud-with-Snow:before {
  content: "\ea44";
}

.scorimmo-icon-Weather-Cloud-with-Sun:before {
  content: "\ea45";
}

.scorimmo-icon-Weather-Droplet:before {
  content: "\ea46";
}

.scorimmo-icon-Weather-Fahrenheit:before {
  content: "\ea47";
}

.scorimmo-icon-Weather-Moon-Cycle-1:before {
  content: "\ea48";
}

.scorimmo-icon-Weather-Moon-Cycle-2:before {
  content: "\ea49";
}

.scorimmo-icon-Weather-Moon-Cycle-3:before {
  content: "\ea4a";
}

.scorimmo-icon-Weather-Moon-Cycle-4:before {
  content: "\ea4b";
}

.scorimmo-icon-Weather-Moon-Cycle-5:before {
  content: "\ea4c";
}

.scorimmo-icon-Weather-Moon-Cycle-6:before {
  content: "\ea4d";
}

.scorimmo-icon-Weather-Moon-Cycle-7:before {
  content: "\ea4e";
}

.scorimmo-icon-Weather-Moon-Cycle-8:before {
  content: "\ea4f";
}

.scorimmo-icon-Weather-Moon-with-Craters:before {
  content: "\ea50";
}

.scorimmo-icon-Weather-Moon:before {
  content: "\ea51";
}

.scorimmo-icon-Weather-Orbit:before {
  content: "\ea52";
}

.scorimmo-icon-Weather-Rainbow:before {
  content: "\ea53";
}

.scorimmo-icon-Weather-Sun:before {
  content: "\ea54";
}

.scorimmo-icon-Weather-Sunrise:before {
  content: "\ea55";
}

.scorimmo-icon-Weather-Sunset:before {
  content: "\ea56";
}

.scorimmo-icon-Weather-Thermometer:before {
  content: "\ea57";
}

.scorimmo-icon-Weather-Umbrella:before {
  content: "\ea58";
}

.scorimmo-icon-Weather-Wind:before {
  content: "\ea59";
}

.scorimmo-icon-Web-Airplay-Logo:before {
  content: "\ea5a";
}

.scorimmo-icon-Web-API:before {
  content: "\ea5b";
}

.scorimmo-icon-Web-At-Symbol:before {
  content: "\ea5c";
}

.scorimmo-icon-Web-Audio-0:before {
  content: "\ea5d";
}

.scorimmo-icon-Web-Audio-1:before {
  content: "\ea5e";
}

.scorimmo-icon-Web-Audio-2:before {
  content: "\ea5f";
}

.scorimmo-icon-Web-Audio-3:before {
  content: "\ea60";
}

.scorimmo-icon-Web-Audio-Muted:before {
  content: "\ea61";
}

.scorimmo-icon-Web-Barcode-Scan:before {
  content: "\ea62";
}

.scorimmo-icon-Web-Barcode-Search:before {
  content: "\ea63";
}

.scorimmo-icon-Web-Barcode:before {
  content: "\ea64";
}

.scorimmo-icon-Web-Battery-1:before {
  content: "\ea65";
}

.scorimmo-icon-Web-Battery-2:before {
  content: "\ea66";
}

.scorimmo-icon-Web-Battery-3:before {
  content: "\ea67";
}

.scorimmo-icon-Web-Battery-4:before {
  content: "\ea68";
}

.scorimmo-icon-Web-Battery-5:before {
  content: "\ea69";
}

.scorimmo-icon-Web-Battery-Charging:before {
  content: "\ea6a";
}

.scorimmo-icon-Web-Bluetooth:before {
  content: "\ea6b";
}

.scorimmo-icon-Web-Boom-Box:before {
  content: "\ea6c";
}

.scorimmo-icon-Web-Browser-with-Compass:before {
  content: "\ea6d";
}

.scorimmo-icon-Web-Browser:before {
  content: "\ea6e";
}

.scorimmo-icon-Web-Browsers:before {
  content: "\ea6f";
}

.scorimmo-icon-Web-Calculator:before {
  content: "\ea70";
}

.scorimmo-icon-Web-Camera-with-Film-Strip:before {
  content: "\ea71";
}

.scorimmo-icon-Web-Camera-with-Polaroid:before {
  content: "\ea72";
}

.scorimmo-icon-Web-Camera-with-Wide-Angle:before {
  content: "\ea73";
}

.scorimmo-icon-Web-Camera:before {
  content: "\ea74";
}

.scorimmo-icon-Web-Cameras:before {
  content: "\ea75";
}

.scorimmo-icon-Web-Chromecast-Logo:before {
  content: "\ea76";
}

.scorimmo-icon-Web-Cloud-Download:before {
  content: "\ea77";
}

.scorimmo-icon-Web-Cloud-Sync:before {
  content: "\ea78";
}

.scorimmo-icon-Web-Cloud-Upload:before {
  content: "\ea79";
}

.scorimmo-icon-Web-Command-Line:before {
  content: "\ea7a";
}

.scorimmo-icon-Web-Desktop-Download:before {
  content: "\ea7b";
}

.scorimmo-icon-Web-Desktop-Left:before {
  content: "\ea7c";
}

.scorimmo-icon-Web-Desktop-Right:before {
  content: "\ea7d";
}

.scorimmo-icon-Web-Desktop-Sync:before {
  content: "\ea7e";
}

.scorimmo-icon-Web-Desktop-Upload:before {
  content: "\ea7f";
}

.scorimmo-icon-Web-Desktop:before {
  content: "\ea80";
}

.scorimmo-icon-Web-DLNA-Logo:before {
  content: "\ea81";
}

.scorimmo-icon-Web-Download-1:before {
  content: "\ea82";
}

.scorimmo-icon-Web-Download-2:before {
  content: "\ea83";
}

.scorimmo-icon-Web-File-Type-AI:before {
  content: "\ea84";
}

.scorimmo-icon-Web-File-Type-GIF:before {
  content: "\ea85";
}

.scorimmo-icon-Web-File-Type-JPG:before {
  content: "\ea86";
}

.scorimmo-icon-Web-File-Type-PDF:before {
  content: "\ea87";
}

.scorimmo-icon-Web-File-Type-PNG:before {
  content: "\ea88";
}

.scorimmo-icon-Web-File-Type-PSD:before {
  content: "\ea89";
}

.scorimmo-icon-Web-Finder:before {
  content: "\ea8a";
}

.scorimmo-icon-Web-Floppy-Disk-1:before {
  content: "\ea8b";
}

.scorimmo-icon-Web-Floppy-Disk-2:before {
  content: "\ea8c";
}

.scorimmo-icon-Web-Game-Controler:before {
  content: "\ea8d";
}

.scorimmo-icon-Web-Global-Connection:before {
  content: "\ea8e";
}

.scorimmo-icon-Web-Hard-Drive:before {
  content: "\ea8f";
}

.scorimmo-icon-Web-hare-1:before {
  content: "\ea90";
}

.scorimmo-icon-Web-Headphones-1:before {
  content: "\ea91";
}

.scorimmo-icon-Web-Headphones-2:before {
  content: "\ea92";
}

.scorimmo-icon-Web-Headphones-3:before {
  content: "\ea93";
}

.scorimmo-icon-Web-Inbox-Download:before {
  content: "\ea94";
}

.scorimmo-icon-Web-Inbox-Full:before {
  content: "\ea95";
}

.scorimmo-icon-Web-Inbox-Upload:before {
  content: "\ea96";
}

.scorimmo-icon-Web-Inbox:before {
  content: "\ea97";
}

.scorimmo-icon-Web-Inboxes:before {
  content: "\ea98";
}

.scorimmo-icon-Web-Keyboard-Down:before {
  content: "\ea99";
}

.scorimmo-icon-Web-Keyboard-Up:before {
  content: "\ea9a";
}

.scorimmo-icon-Web-Keyboard:before {
  content: "\ea9b";
}

.scorimmo-icon-Web-Laptop:before {
  content: "\ea9c";
}

.scorimmo-icon-WEb-Link-Logo:before {
  content: "\ea9d";
}

.scorimmo-icon-Web-Mail:before {
  content: "\ea9e";
}

.scorimmo-icon-Web-Mails:before {
  content: "\ea9f";
}

.scorimmo-icon-Web-Megaphone-1:before {
  content: "\eaa0";
}

.scorimmo-icon-Web-Megaphone-2:before {
  content: "\eaa1";
}

.scorimmo-icon-Web-Megaphone-3:before {
  content: "\eaa2";
}

.scorimmo-icon-Web-Microphone-1:before {
  content: "\eaa3";
}

.scorimmo-icon-Web-Microphone-2:before {
  content: "\eaa4";
}

.scorimmo-icon-Web-Mobile:before {
  content: "\eaa5";
}

.scorimmo-icon-Web-Mouse:before {
  content: "\eaa6";
}

.scorimmo-icon-Web-Old-TV:before {
  content: "\eaa7";
}

.scorimmo-icon-Web-OS-Android:before {
  content: "\eaa8";
}

.scorimmo-icon-Web-OS-Windows:before {
  content: "\eaa9";
}

.scorimmo-icon-Web-OS-X:before {
  content: "\eaaa";
}

.scorimmo-icon-Web-Paper-Shredder:before {
  content: "\eaab";
}

.scorimmo-icon-Web-Phone-1:before {
  content: "\eaac";
}

.scorimmo-icon-Web-Phone-2:before {
  content: "\eaad";
}

.scorimmo-icon-Web-Phone-3:before {
  content: "\eaae";
}

.scorimmo-icon-Web-Phone-4:before {
  content: "\eaaf";
}

.scorimmo-icon-Web-Phones-1:before {
  content: "\eab0";
}

.scorimmo-icon-Web-Phones-2:before {
  content: "\eab1";
}

.scorimmo-icon-Web-Photo:before {
  content: "\eab2";
}

.scorimmo-icon-Web-Photos-2:before {
  content: "\eab3";
}

.scorimmo-icon-Web-Photos-3:before {
  content: "\eab4";
}

.scorimmo-icon-Web-Photos:before {
  content: "\eab5";
}

.scorimmo-icon-Web-Podcasts-1:before {
  content: "\eab6";
}

.scorimmo-icon-Web-Podcasts-2:before {
  content: "\eab7";
}

.scorimmo-icon-Web-Power:before {
  content: "\eab8";
}

.scorimmo-icon-Web-Printer:before {
  content: "\eab9";
}

.scorimmo-icon-Web-Radio:before {
  content: "\eaba";
}

.scorimmo-icon-Web-Record-Player:before {
  content: "\eabb";
}

.scorimmo-icon-Web-Refresh-1:before {
  content: "\eabc";
}

.scorimmo-icon-Web-Refresh-2:before {
  content: "\eabd";
}

.scorimmo-icon-Web-Refresh-3:before {
  content: "\eabe";
}

.scorimmo-icon-Web-Refresh-4:before {
  content: "\eabf";
}

.scorimmo-icon-Web-Router:before {
  content: "\eac0";
}

.scorimmo-icon-Web-RSS-1:before {
  content: "\eac1";
}

.scorimmo-icon-Web-RSS-2:before {
  content: "\eac2";
}

.scorimmo-icon-Web-Server-Connections:before {
  content: "\eac3";
}

.scorimmo-icon-Web-Servers:before {
  content: "\eac4";
}

.scorimmo-icon-Web-Share-2:before {
  content: "\eac5";
}

.scorimmo-icon-Web-Share-3:before {
  content: "\eac6";
}

.scorimmo-icon-Web-Tablet:before {
  content: "\eac7";
}

.scorimmo-icon-Web-TV-Fuzz:before {
  content: "\eac8";
}

.scorimmo-icon-Web-TV:before {
  content: "\eac9";
}

.scorimmo-icon-Web-Video-Camera-1:before {
  content: "\eaca";
}

.scorimmo-icon-Web-Video-Camera-2:before {
  content: "\eacb";
}

.scorimmo-icon-Web-Video-Camera-3:before {
  content: "\eacc";
}

.scorimmo-icon-Web-Video-Camera-4:before {
  content: "\eacd";
}

.scorimmo-icon-Web-WiFi-Logo:before {
  content: "\eace";
}