.--flex {
  display: flex;
}
.--item-flex-start {
  align-items: flex-start;
}
.--item-stretch {
  align-items: stretch;
}
.--align-items-center {
  align-items: center;
}
.--space-between {
  justify-content: space-between;
}
.--space-around {
  justify-content: space-around;
}
.--justify-content-center {
  justify-content: center;
}
.--justify-content-end {
  justify-content: flex-end;
}
.--justify-content-start {
  justify-content: flex-start;
}
.--flex-gap {
  gap: 20px
}
.--flex-wrap {
  flex-wrap: wrap;
}
.flex-space-between {
  justify-content: space-between;
}
.--space-evenly {
  justify-content: space-evenly;
}
.--full-width {
  width: 100%;
}
.--flex-column {
  flex: 1;
}
.--flex-direction-column {
  flex-direction: column;
}