.--no-pointer {
  cursor: initial !important;
}

.button-icon {
  border-radius: 100%;
  color: rgb(0, 1, 107);
  text-align: center;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  font-size: 20px;
  display: block;
  transition: 0.1s;
  background-color: transparent;
}
.button-icon:hover {
  background-color: rgba(0, 1, 107, 0.3);
  transition: all 0.3s ease;
}
.button-icon:active {
  background-color: rgba(0, 1, 107, 0.3);
  transition: all 0.3s ease;
}
.button-icon.--dark {
  color: rgb(255, 255, 255);
}
.button-icon.--dark:hover {
  background-color: rgba(190, 240, 224, 0.5);
}
.button-icon.--dark:active {
  background-color: rgba(190, 240, 224, 0.4);
}

.button-default, .button-defaut {
  background-color: rgb(0, 1, 104);
  border-radius: 3px;
  color: white;
  padding: 2px 25px;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  display: block;
  transition: 0.1s;
}
.button-default:hover, .button-defaut:hover {
  background-color: rgba(0, 1, 104, 0.6);
  transition: all 0.3s ease;
}
.button-default:active, .button-defaut:active {
  background-color: rgba(0, 1, 104, 0.4);
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
}
.button-default.--grey-btn, .button-default.--cancel, .button-defaut.--grey-btn, .button-defaut.--cancel {
  background-color: #777;
}
.button-default.--grey-btn:hover, .button-default.--grey-btn:active, .button-default.--cancel:hover, .button-default.--cancel:active, .button-defaut.--grey-btn:hover, .button-defaut.--grey-btn:active, .button-defaut.--cancel:hover, .button-defaut.--cancel:active {
  background-color: rgba(119, 119, 119, 0.5019607843);
  transition: all 0.3s ease;
}
.button-default.--delete, .button-defaut.--delete {
  background-color: rgb(238, 52, 50);
}
.button-default.--delete:hover, .button-default.--delete:active, .button-defaut.--delete:hover, .button-defaut.--delete:active {
  background-color: rgba(238, 52, 50, 0.5);
  transition: all 0.3s ease;
}
.button-default.--disabled, .button-defaut.--disabled {
  cursor: default;
  background-color: rgba(0, 1, 107, 0.3);
}
.button-default.--lightgrey-btn, .button-defaut.--lightgrey-btn {
  background: #CCCCCC;
}
.button-default.--lightgrey-btn:hover, .button-defaut.--lightgrey-btn:hover {
  background: rgba(204, 204, 204, 0.5);
}
.button-default.--green-btn, .button-defaut.--green-btn {
  background-color: rgb(72, 135, 74);
}
.button-default.--green-btn:hover, .button-defaut.--green-btn:hover {
  background: rgba(72, 135, 74, 0.5);
}
.button-default.--dark-green-btn, .button-defaut.--dark-green-btn {
  background-color: rgb(15, 104, 116);
}
.button-default.--dark-green-btn:hover, .button-defaut.--dark-green-btn:hover {
  background: rgba(15, 104, 116, 0.5);
}
.button-default.--orange-btn, .button-defaut.--orange-btn {
  background-color: rgba(255, 75, 0, 0.8);
}
.button-default.--orange-btn:hover, .button-defaut.--orange-btn:hover {
  background: rgba(255, 75, 0, 0.3);
}
.button-default.--lime-btn, .button-defaut.--lime-btn {
  background: rgb(164, 196, 0);
}
.button-default.--lime-btn:hover, .button-default.--lime-btn:active, .button-defaut.--lime-btn:hover, .button-defaut.--lime-btn:active {
  background: rgba(164, 196, 0, 0.6);
}
.button-default.--cyan-btn, .button-defaut.--cyan-btn {
  background: rgb(21, 139, 219);
}
.button-default.--cyan-btn:hover, .button-default.--cyan-btn:active, .button-defaut.--cyan-btn:hover, .button-defaut.--cyan-btn:active {
  background: rgba(21, 139, 219, 0.6);
}
.button-default.--yellow-btn, .button-defaut.--yellow-btn {
  background: rgb(241, 204, 0);
}
.button-default.--yellow-btn:hover, .button-default.--yellow-btn:active, .button-defaut.--yellow-btn:hover, .button-defaut.--yellow-btn:active {
  background: rgba(241, 204, 0, 0.6);
}
.button-default.--red-btn, .button-defaut.--red-btn {
  background: rgb(238, 52, 50);
}
.button-default.--red-btn:hover, .button-default.--red-btn:active, .button-defaut.--red-btn:hover, .button-defaut.--red-btn:active {
  background: rgba(238, 52, 50, 0.6);
}
.button-default.--pink-btn, .button-defaut.--pink-btn {
  background: rgb(243, 159, 175);
}
.button-default.--pink-btn:hover, .button-default.--pink-btn:active, .button-defaut.--pink-btn:hover, .button-defaut.--pink-btn:active {
  background: rgba(243, 159, 175, 0.6);
}

input[type=checkbox] {
  height: 17px;
  width: 17px;
  position: relative;
  -webkit-appearance: none;
  cursor: pointer;
}

input[type=checkbox]:before {
  content: "";
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  height: 17px;
  width: 17px;
  border-radius: 4px;
  border: 1px solid rgba(0, 1, 107, 0.3);
  background-color: transparent;
  transition: 0.3s ease;
}

input[type=checkbox]:hover::before {
  border: 1px solid rgba(0, 1, 107, 0.6);
  background-color: rgba(0, 1, 107, 0.1);
}

input[type=checkbox]:hover:after {
  font-family: "icoscorimmo";
  font-weight: normal;
  color: rgba(0, 1, 107, 0.4);
  content: "\e99e";
  display: inline-block;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
}

input[type=checkbox]:checked:before {
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  border: 1px solid rgba(0, 1, 107, 0.6);
}

input[type=checkbox]:checked:after {
  font-family: "icoscorimmo";
  font-weight: bold;
  color: rgb(0, 1, 107);
  content: "\e99e";
  display: inline-block;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
}