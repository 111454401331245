@import "colors";

/* begin reset */
html {
  font-size: 100%;
  font-family: arial, helvetica, sans-serif;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.4;
  height: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  margin: 0;
}

a {
  color: $greyText;
  text-decoration: none;
  outline: none;
}

img {
  border: none;
}

form {
  margin: 0;
  padding: 0;
  border: none;
}

input,select {
  vertical-align: middle;
}
h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0;
}

input::-webkit-input-placeholder {
  color: #000;
}
input:focus::-webkit-input-placeholder {
  color: #999;
}

/* Firefox < 19 */
input:-moz-placeholder {
  color: #000;
}
input:focus:-moz-placeholder {
  color: #999;
}

/* Firefox > 19 */
input::-moz-placeholder {
  color: #000;
}
input:focus::-moz-placeholder {
  color: #999;
}

/* Internet Explorer 10 */
input:-ms-input-placeholder {
  color: #000;
}
input:focus:-ms-input-placeholder {
  color: #999;
}
::-webkit-input-placeholder {
  color: #000;
}


input,textarea {
  margin: 0;
}

input,input[type=text],input[type=password],input[type=email],
select,textarea,input[type=submit] {
  font-size: 1em;
  font-family: opensans,arial,helvetica,sans-serif;
  outline: none;
}
