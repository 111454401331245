/****************************  FLASH MESSAGES  ********************************/
.flash-message {
  padding: 10px;
  -webkit-border-radius: 4px;
  border-radius: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.layout-flash {
  margin-top: 10px;
  margin-right: 10px;
}

.cti-flash-message {
  margin-left: 10px;
}

.flash-message a {
  text-decoration: underline;
}

.flash-success {
  color: #48874a;
  padding-left: 20px;
  background: rgba(190, 240, 224, 0.6);
}

.flash-success .scorimmo-icon-Other-Checkmark-2 {
  font-size: 20px;
  margin-right: 20px;
}

.flash-error {
  color: #d82828;
  padding-left: 20px;
  background: rgba(241, 200, 200, 0.6);
}

.flash-error .scorimmo-icon-Other-No-Entry {
  font-size: 20px;
  margin-right: 20px;
}

.flash-error .scorimmo-icon-Other-X::before, .flash-success .scorimmo-icon-Other-X::before {
  font-size: 12px;
  display: block;
  margin-top: 4px;
}

.flash-error .button-icon, .flash-success .button-icon {
  margin-top: 3px;
}

.flash-notice {
  color: #8a6d3b;
  border: 1px solid #faebcc;
  padding-left: 50px;
  background-color: #fcf8e3;
}

.close-flash {
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 8px;
  top: 10px;
  text-decoration: none !important;
}

.flash-error #enableNotifications {
  text-decoration: none;
  width: fit-content;
  display: inline-block;
  margin-left: 10px;
}