$darkBlue: #18212b;
$greyText: #3a3939;
$greyText2: #777;
$lightGreyBackground: #f3f3f3;
$lightGreyBackground2: #f4f4f4;
$red: #d75553;
// bleu
@function chartSky($opacity: 1) {
  @return rgba(0, 1, 107, $opacity);
}
// rouge
@function chartFirework($opacity: 1) {
  @return rgba(238, 52, 50, $opacity);
}
// orange
@function chartSpark($opacity: 1) {
  @return rgba(255, 75, 0, $opacity);
}
// rose
@function chartTwinkle($opacity: 1) {
  @return rgba(255, 153, 162, $opacity);
}
// jaune
@function chartFlash($opacity: 1) {
  @return rgba(241, 204, 0, $opacity);
}
// vert clair
@function chartShimmer($opacity: 1) {
  @return rgba(190, 240, 224, $opacity);
}
@function chartAssigned($opacity: 1) {
  @return rgba(170, 0, 255, $opacity);
}
@function chartWhite($opacity: 1) {
  @return rgba(255, 255, 255, $opacity);
}
@function chartDMS($opacity: 1) {
  @return rgba(65, 187, 203, $opacity);
}
@function chartAppointment($opacity: 1) {
  @return rgba(21, 139, 219, $opacity);
}
@function chartRecontact($opacity: 1) {
  @return rgba(243, 159, 175, $opacity);
}
@function chartSale($opacity: 1) {
  @return rgba(28, 134, 25, $opacity);
}
@function chartMandate($opacity: 1) {
  @return rgba(70, 181, 116 , $opacity);
}
@function chartLost($opacity: 1) {
  @return rgba(241, 156, 0, $opacity);
}
@function chartAbandoned($opacity: 1) {
  @return rgba(119, 119, 119, $opacity);
}
@function chartToCome($opacity: 1) {
  @return rgba(154, 127, 201, $opacity);
}