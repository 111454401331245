@import "colors";

.--no-pointer {
  cursor: initial !important;
}
.button-icon {
  border-radius: 100%;
  color: chartSky();
  text-align: center;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  font-size: 20px;
  display: block;
  transition: 0.1s;
  background-color: transparent;

  &:hover {
    background-color: chartSky(.3);
    transition: all 0.3s ease;
  }

  &:active {
    background-color: chartSky(.3);
    transition: all 0.3s ease
  }

  &.--dark {
    color: rgb(255,255,255);
    &:hover {
      background-color: rgba(190, 240, 224, .5);
    }
    &:active {
      background-color: rgba(190, 240, 224, .4);
    }
  }
}

// button-defaut pour les classes en dur dans la bdd (table note "ajouter à mon agenda")
.button-default, .button-defaut {
  background-color: rgba(0,1,104,1);
  border-radius: 3px;
  color: white;
  padding: 2px 25px;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  display: block;
  transition: 0.1s;
  &:hover {
    background-color: rgba(0,1,104,.6);
    transition: all 0.3s ease
  }
  &:active {
    background-color:rgba(0,1,104,.4);
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease
  }

  &.--grey-btn, &.--cancel {
    background-color: $greyText2;
    &:hover, &:active {
      background-color: #77777780;
      transition: all 0.3s ease
    }
  }
  &.--delete {
    background-color: rgba(238, 52, 50, 1);;
    &:hover, &:active {
      background-color: rgba(238, 52, 50, .5);;
      transition: all 0.3s ease
    }
  }
  &.--disabled {
    cursor: default;
    background-color: chartSky(.3);
  }
  &.--lightgrey-btn {
    background: #CCCCCC;
    &:hover {
      background: rgba(204,204,204,.5);
    }
  }
  &.--green-btn {
    background-color: rgba(72, 135, 74, 1);
    &:hover {
      background: rgba(72, 135, 74, .5);
    }
  }
  &.--dark-green-btn {
    background-color: rgba(15, 104, 116, 1);
    &:hover {
      background: rgba(15, 104, 116, .5);
    }
  }
  &.--orange-btn {
    background-color: rgba(255, 75, 0, 0.8);
    &:hover {
      background: rgba(255, 75, 0,.3);
    }
  }
  &.--lime-btn {
    background: rgb(164, 196, 0);
    &:hover, &:active {
      background: rgba(164, 196, 0, .6);
    }
  }
  &.--cyan-btn {
    background: rgb(21,139,219);
    &:hover, &:active {
      background: rgba(21,139,219,.6);
    }
  }
  &.--yellow-btn {
    background: chartFlash(1);
    &:hover, &:active {
      background: chartFlash(.6);
    }
  }
  &.--red-btn {
    background: chartFirework(1);
    &:hover, &:active {
      background: chartFirework(.6);
    }
  }
  &.--pink-btn {
    background: rgb(243, 159, 175);
    &:hover, &:active {
      background: rgba(243, 159, 175, .6);
    }
  }
}



input[type="checkbox"] {
  height: 17px;
  width: 17px;
  position: relative;
  -webkit-appearance: none;
  cursor:pointer
}

input[type="checkbox"]:before {
  content: "";
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  height: 17px;
  width: 17px;
  border-radius: 4px;
  border: 1px solid rgba(0,1,107,0.3);
  background-color: transparent;
  transition: 0.3s ease;

}

input[type="checkbox"]:hover::before {
  border: 1px solid rgba(0,1,107,0.6);
  background-color: rgba(0,1,107,0.1);
}
input[type="checkbox"]:hover:after {
  font-family: "icoscorimmo";
  font-weight: normal;
  color:rgba(0,1,107,0.4);
  content: "\e99e";
  display: inline-block;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
}


input[type="checkbox"]:checked:before {
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  border: 1px solid rgba(0,1,107,0.6);

}

input[type="checkbox"]:checked:after {
  font-family: "icoscorimmo";
  font-weight: bold;
  color:rgba(0,1,107,1.0);
  content: "\e99e";
  display: inline-block;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
}